import ProfileMediaList from './ProfileMediaList';
import React, { useContext } from 'react';
import LoadingSpinner from '../commons/LoadingSpinner';
import RedError from '../RedError';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import AppContext from '../AppContext';

const EditProfileSettings = props => {
  if (props.isUser) {
    return (
      <Link
        to="/settings"
        className="btn btn-sm btn-outline-secondary action-btn">
        <i className="ion-gear-a" /> Edit Profile Settings
      </Link>
    );
  }
  return null;
};

const FollowUserButton = props => {
  if (props.isUser) {
    return null;
  }

  let classes = 'btn btn-sm action-btn';
  if (props.following) {
    classes += ' btn-secondary';
  } else {
    classes += ' btn-outline-secondary';
  }

  const handleClick = ev => {
    ev.preventDefault();
    if (props.following) {
      props.unfollow();
    } else {
      props.follow();
    }
  };

  return (
    <button
      className={classes}
      onClick={handleClick}
    >
      <i className="ion-plus-round" />
      &nbsp;
      {props.following ? 'Unfollow' : 'Follow'} {props.username}
    </button>
  );
};

class Profile extends React.Component {
  UNSAFE_componentWillMount() {
    this.loadData();
  }
  componentDidMount(){
    window.scrollTo(0, 0);

  }
  componentDidUpdate(previousProps) {
    if (this.props.location !== previousProps.location) {
      this.loadData();
      window.scrollTo(0, 0);
    }
  }
  async loadData(){
    await this.props.profileStore.clear();
    await this.props.profileStore.loadProfile(this.props.match.params.username);
    this.props.profileStore.loadProfileInfo(this.props.match.params.username);
    await this.props.profileMediasStore.clear();
    await this.props.profileMediasStore.loadUserMedia(this.props.match.params.username);
  }
  handleFollow = () => this.props.profileStore.follow();
  handleUnfollow = () => {
    this.props.profileStore.unfollow();
  }

  getProfileImage(){
    if(this.props.profileData.profile && this.props.profileData.profile.profilePictureMedium){
      return this.props.profileData.profile.profilePictureMedium.url;
    }
    return "https://khmer.be/public/images/no-img.jpg";
  }
  
  render() {
    const { userStore, medias, isLoadingMedia } = this.props;
    const { profile, profileinfo, isLoadingProfile, isLoadingProfileInfo } = this.props.profileData;
    const { currentUser } = userStore;

    if ((isLoadingProfile || isLoadingProfileInfo) && !profile) return <LoadingSpinner />;
    if (!profile) return <RedError message="Can't load profile" />;

    const isUser = currentUser && profile.username === currentUser.username;

    return (
      <React.Fragment>
      <div className="profile-page">
        <div className="user-info">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-12">

                <img src={this.getProfileImage()} className="user-img" alt="" />
                <h4>{profile.username}</h4>
                {profileinfo && profileinfo.about && <p>{profileinfo.about}</p>}

                <EditProfileSettings isUser={isUser} />
                <FollowUserButton
                  isUser={isUser}
                  username={profile.username}
                  following={profileinfo && profileinfo.isfollowing}
                  follow={this.handleFollow}
                  unfollow={this.handleUnfollow}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
      <div role="main" className="container mt-2">
        
        <div className="row d-flex justify-content-center">
          <div className="col-md-7 col-xs-12">
            <ProfileMediaList username={profile.username} medias={medias} loading={isLoadingMedia} />
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

const view = observer((props) => {
  const { profileStore, userStore, profileMediasStore } = useContext(AppContext);
  const { profile, profileinfo, isLoadingProfile, isLoadingProfileInfo } = profileStore;
  const { medias, isLoading } = profileMediasStore;
  return (<Profile {...props} 
    profileStore={profileStore} 
    userStore={userStore} 
    profileMediasStore={profileMediasStore} 
    profileData={{profile, profileinfo, isLoadingProfile, isLoadingProfileInfo}}
    medias={medias}
    isLoadingMedia={isLoading}
  />);
});

export default withRouter(view);