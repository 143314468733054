import React, { Fragment } from 'react';
import { Switch, Route, withRouter} from 'react-router-dom';
import { observer } from 'mobx-react';
import Article from './Article';
import Editor from './Editor';
import Home from './Home';
import Login from './Login';
import Profile from './Profile';
import Register from './Register';
import Settings from './Settings';
import Comment from './Comment';
import PageNotFound from './Notfound';
import PrivateRoute from './PrivateRoute';
import HeaderNav from './Header/HeaderNav';
import AppContext from './AppContext';
import { useContext } from "react";

const app = observer(() => {
  const stores = useContext(AppContext);
  const { appLoaded, userLoggedIn } = stores.commonStore;
  const { currentUser } = stores.userStore;
  
  if(userLoggedIn && !currentUser && !appLoaded){
    console.log('pullUser() in app');
    stores.userStore.pullUser()
    .finally(() => stores.commonStore.setAppLoaded());
  }
  
  if(userLoggedIn && !appLoaded){
    return (
      <Fragment>
        <HeaderNav currentUser={currentUser} />
        <center>
          <h3>Loading...</h3>
        </center>
      </Fragment>
    );
  }else{
    return (
      <Fragment>
        <HeaderNav currentUser={currentUser}/>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <PrivateRoute path="/editor">
            <Editor />
          </PrivateRoute>
          <PrivateRoute path="/editor/:slug">
            <Editor />
          </PrivateRoute>
          <Route path="/article/:id">
            <Article />
          </Route>
          <PrivateRoute path="/settings">
            <Settings />
          </PrivateRoute>
          <Route path="/@:username">
            <Profile />
          </Route>
          <Route path="/@:username/favorites">
            <Profile />
          </Route>
          <Route path="/viewcomment/:id">
            <Comment />
          </Route>
          <Route>
            <PageNotFound />
          </Route>
        </Switch>
        
      </Fragment>
    );
  }
})

export default withRouter(app);