import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Dropdown, DropdownMenu, DropdownToggle, Badge } from 'reactstrap';
import AppContext from '../AppContext';

class NotificationBox extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }
  componentDidMount(){
      this.props.notificationStore.clear();
      this.props.notificationStore.loadData();
  }
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
    if(this.props.totalnew > 0){
        this.props.notificationStore.resetTotalNew();
    }
  }
  handleFollowButtonClick = item =>{
      this.props.notificationStore.handleFollow(item);
  }
  render() {
      var items = this.props.data.map((item) =>{
          return <NotificationItem key={item.objectId} handleFollow={this.handleFollowButtonClick} data={item} />
      });
      if(items.length < 2){
          items = <li className="list-group-item">You have 0 notification</li>;
      }
      const badgeText = this.props.totalnew > 0 ? <Badge color="danger">{this.props.totalnew}</Badge> : null
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle
          onClick={this.toggle}
          data-toggle="dropdown"
          aria-expanded={this.state.dropdownOpen}
        >
          <span className="ion-earth noti-icon" /> {badgeText}
        </DropdownToggle>
        <DropdownMenu
            modifiers={{
            setMaxHeight: {
                enabled: true,
                order: 890,
                fn: (data) => {
                return {
                    ...data,
                    styles: {
                    ...data.styles,
                    overflow: 'auto',
                    maxHeight: 400,
                    minWidth: 300,
                    },
                };
                },
            },
            }}
        >
        <ul className="list-group list-group-flush">
            {items}
        </ul>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const NotificationItem = props => {
    const text = createText(props.data);
    const rightElement = createRightElement(props.data, props.handleFollow);
    const bg = props.data.seen ? '' : 'newnotification';
    return(
        <li className={`list-group-item d-flex justify-content-between align-items-center pl-1 pr-1 ${bg}`}>
            <a href={`/@${props.data.actorone.username}`}><img src={props.data.actorone.profilePictureSmall.url} className="user-noti-icon mr-1" alt=""/></a>
            {text}
            {rightElement}
        </li>
    );
}
const createText = data =>{
    if(data.activityType === "followed"){
        return <span><a href={`/@${data.actorone.username}`}>{data.actorone.username}</a> followed you</span>;
    }else if(data.activityType === "commentedsomeonephoto"){
        return <span><a href={`/@${data.actorone.username}`}>{data.actorone.username}</a> and {data.totalactor} others commented on <a href={`/@${data.actortwo.username}`}>{data.actortwo.username}</a>'s photo</span>;
    }else if(data.activityType === "commentedphoto"){
        if(data.totalactor === 1){
            return <span><a href={`/@${data.actorone.username}`}>{data.actorone.username}</a> commented on your photo</span>;
        }else if(data.totalactor === 2){
            return <span><a href={`/@${data.actorone.username}`}>{data.actorone.username}</a> and <a href={`/@${data.actortwo.username}`}>{data.actortwo.username}</a> commented on your photo</span>;
        }
        const count = data.totalactor - 2;
        return <span><a href={`/@${data.actorone.username}`}>{data.actorone.username}</a>, <a href={`/@${data.actortwo.username}`}>{data.actortwo.username}</a> and {count} others commented on your photo</span>;
    }else if(data.activityType === "likedphoto"){
        if(data.totalactor === 1){
            return <span><a href={`/@${data.actorone.username}`}>{data.actorone.username}</a> liked your photo</span>;    
        }else if(data.totalactor === 2){
            return <span><a href={`/@${data.actorone.username}`}>{data.actorone.username}</a> and <a href={`/@${data.actortwo.username}`}>{data.actortwo.username}</a> liked your photo</span>;
        }
        const count = data.totalactor - 2;
        return <span><a href={`/@${data.actorone.username}`}>{data.actorone.username}</a>, <a href={`/@${data.actortwo.username}`}>{data.actortwo.username}</a> and {count} others liked your photo</span>;
    }else if(data.activityType === "friendjoined"){
        return <span>Your Facebook friend <a href={`/@${data.actorone.username}`}>{data.actorone.displayName}</a> joined as <a href={`/@${data.actorone.username}`}>{data.actorone.username}</a></span>;
    }
}
const createRightElement = (data, handleFollow) =>{
    if(data.activityType === "followed" || data.activityType === "friendjoined"){
        if(data.object.following){
            return <button type="button" className="btn btn-outline-secondary btn-sm" 
                    onClick={() => {
                        data.object.following = false;
                        handleFollow(data);
                    }}>Following</button>    
        }
        return <button type="button" className="btn btn-outline-primary btn-sm" 
                    onClick={() => {
                        data.object.following = true;
                        handleFollow(data);
                    }}>Follow</button>
    }else if(data.activityType === "commentedphoto" || 
            data.activityType === "likedphoto" || 
            data.activityType === "commentedsomeonephoto"){
        return <a href={`/viewcomment/${data.object.objectId}`}><img src={data.object.image} className="media-noti-icon ml-1" alt=""/></a>
    }
}

export default observer((props) => {
    const { notificationStore } = useContext(AppContext);
    const { data, totalnew } = notificationStore;
    return(
        <NotificationBox {...props} notificationStore={notificationStore} data={data} totalnew={totalnew} />
    );
});