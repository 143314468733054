import { makeAutoObservable, action } from 'mobx';
import agent from '../agent';

class UserStore {
  currentUser = undefined;
  loadingUser = false;
  updatingUser = false;
  updatingUserErrors = undefined;
  updatingUserSuccess = undefined;

  constructor(){
    makeAutoObservable(this);
    console.log('UserStore init');
  }

  get isLoggedIn() {
    return this.currentUser && this.currentUser.objectId;
  }

  pullUser() {
    this.loadingUser = true;
    return agent.Auth.current()
      .then(action( user => { 
        this.currentUser = user;
      }))
      .finally(action(() => { this.loadingUser = false; }))
  }

  setCurrentUser(userObj) {
    this.currentUser = userObj;
  }

  clearUpdatingUserStat(){
    this.updatingUserErrors = undefined;
    this.updatingUserSuccess = undefined;
  }
  updateUser(newUser) {
    this.updatingUser = true;
    return agent.Auth.save(newUser)
      .then(action( user => {
        this.currentUser = user;
        this.updatingUserErrors = undefined;
        this.updatingUserSuccess = "Updated Successfully!";
      }))
      .catch(action((err) => {
        this.updatingUserErrors = err.response && err.response.body && err.response.body.error;
      }))
      .finally(action(() => { this.updatingUser = false; }))
  }

  forgetUser() {
    this.currentUser = undefined;
  }

}

export default new UserStore();
