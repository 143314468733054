import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import commonStore from './stores/commonStore';
import authStore from './stores/authStore';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = '/api/v2';

const encode = encodeURIComponent;

const handleErrors = err => {
  if (err && err.response && err.response.status === 401) {
    authStore.logout();
  }
  return err;
};

const responseBody = res => {
  return res.body
};

const tokenPlugin = req => {
  if (commonStore.token) {
    req.set('X-Parse-Session-Token', commonStore.token);
  }
};

const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
};

const Auth = {
  current: () => {
    return requests.get('/user-me');
  },
  login: (email, password) =>
    requests.post('/users/signin', {email, password}),
  fblogin: authData =>
    requests.post('/users', authData),
  register: (username, email, password) =>
    requests.post('/users', { username, email, password }),
  save: user =>
    requests.put('/user/'+commonStore.userid, user)
};

const Tags = {
  getAll: () => requests.get('/tags')
};

const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
const omitSlug = article => Object.assign({}, article, { slug: undefined })

const Articles = {
  all: (page, lim = 10) =>
    requests.get(`/articles?${limit(lim, page)}`),
  byAuthor: (author, page, query) =>
    requests.get(`/articles?author=${encode(author)}&${limit(5, page)}`),
  byTag: (tag, page, lim = 10) =>
    requests.get(`/articles?tag=${encode(tag)}&${limit(lim, page)}`),
  del: slug =>
    requests.del(`/articles/${slug}`),
  favorite: slug =>
    requests.post(`/articles/${slug}/favorite`),
  favoritedBy: (author, page) =>
    requests.get(`/articles?favorited=${encode(author)}&${limit(5, page)}`),
  feed: () =>
    requests.get('/articles/feed?limit=10&offset=0'),
  get: slug =>
    requests.get(`/articles/${slug}`),
  unfavorite: slug =>
    requests.del(`/articles/${slug}/favorite`),
  update: article =>
    requests.put(`/articles/${article.slug}`, { article: omitSlug(article) }),
  create: article =>
    requests.post('/articles', { article })
};

const Medias = {
  all: (page, lim = 15) =>{
    return requests.get(`/media?limit=${lim}&page=${page}`);
  },
  byUser: (username, page) =>{
    return requests.get(`/media?username=${username}&limit=20&page=${page}`);
  },
  addComment: (mediaId, commentText) =>{
    return requests.post('/mediacomment',{mediaid: mediaId, comment: commentText, userid: commonStore.userid});
  },
  getOne: (mediaid) =>{
    return requests.get(`/media/${mediaid}`);
  },
  getComment: (mediaid, page = 1, lim = 20) =>{
    return requests.get(`/comment?id=${mediaid}&page=${page}&limit=${lim}`);
  }
};
const Notification = {
  getAll : (page, limit = 30) => requests.get(`/notification?page=${page}&limit=${limit}`),
  reset : () => requests.post('/notification/reset')
};
const MediaFeed ={
  getFeed: (page, lim = 15) =>{
    return requests.get(`/mediafeed?limit=${lim}&page=${page}`);
  }
};
const Explore = {
  getData: () =>{
    return requests.get('/explore');
  }
};
const Comments = {
  create: (slug, comment) =>
    requests.post(`/articles/${slug}/comments`, { comment }),
  delete: (slug, commentId) =>
    requests.del(`/articles/${slug}/comments/${commentId}`),
  forArticle: slug =>
    requests.get(`/articles/${slug}/comments`)
};

const Profile = {
  follow: userid =>
    requests.post(`/follow/${userid}`),
  post: username =>
    requests.get(`/user/${username}`),
  getinfo: username => requests.get(`/profile/${username}`),
  unfollow: userid =>
    requests.post(`/unfollow/${userid}`)
};
const agent = {
  Articles,
  Auth,
  Comments,
  Profile,
  Tags,
  Medias,
  MediaFeed,
  Explore,
  Notification
};
export default agent;