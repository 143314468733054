import React from 'react';
import Modal from 'reactstrap/lib/Modal';
import { observer } from 'mobx-react';

export default observer(props => {
  const { imageModalStore, toggle } = props;
  const { imageurl, showmodal} = imageModalStore;
  if(!imageurl) {
    return null;
  }
  const externalCloseBtn = <div className="btnclose" size='sm' onClick={toggle}>&times;</div>;
  return(
    <Modal isOpen={showmodal} toggle={toggle} size="lg" className='mymodal modal-dialog-centered' external={externalCloseBtn}>
      <img src={imageurl} className="rounded mx-auto d-block img-fluid" alt="..." />
    </Modal>
  );
});