import React from 'react';

export default class Notfound extends React.Component {
  render() {

    return (
    <div className="container page d-flex flex-column justify-content-center align-items-center">
        <div className="row">
          <div className="col">
            <h1>Page Not Found :(</h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h3>The page you are looking for is not found.</h3>
          </div>
        </div>
    </div>
    );
  }
}
