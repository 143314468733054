import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Alert } from 'reactstrap';
import FollowSuggestion from './FollowSuggestion';
import AppContext from '../AppContext';
import { shuffleArray } from '../../utils';

class ExploreBox extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showAllert: false,
            alertMessage : '',
        }
        this.hasShuffled = false;
    }
    componentDidMount(){
        this.props.exploreStore.loadData();
    }
    onFollowClick = (userId, name, index) =>{
        this.props.exploreStore.follow(userId, index);
        this.setState({showAllert: true, alertMessage: 'Successfully followed '+name});
    }
    onDismissAlert = () => {
        this.setState({ showAllert: false, alertMessage: '' });
    }
    render() {
        const medias = this.props.medias;
        if(!medias){
            return null;
        }
        if (!this.hasShuffled) {
            this.hasShuffled = true;
            shuffleArray(this.props.medias);
            console.log('Shuttled Array of explore data');
        }
        const alert = this.state.showAllert && <Alert color="primary" isOpen={true} toggle={this.onDismissAlert}>
        {this.state.alertMessage}
      </Alert>;
        return(
        <React.Fragment>
            <div className="row d-flex flex-column flex-wrap justify-content-center align-items-center mb-1">
                <div className="col-12 col-md-6">
                    {alert}
                </div>
            </div>
        <div className="row d-flex flex-row flex-wrap justify-content-center mb-3">
        {
            medias.slice(0, 4).map((media,index) =>{
                return(
                <FollowSuggestion key={`sgid-${media.objectId}`} 
                    onFollowClick={this.onFollowClick}
                    media={media}
                    index={index}
                />
                );
            })
        }
        </div>
        </React.Fragment>
        );
    }
}

export default observer((props) => {
    const{ exploreStore } = useContext(AppContext);
    const { medias } = exploreStore;
    return (<ExploreBox {...props} exploreStore={exploreStore} medias={medias} />);
});
