import React from 'react';
import { observer } from 'mobx-react';

const ShowLiker = media =>{
  if(!media.likers || media.likers.length < 1){
    return null;
  }
  return(
    <div>
    {
      media.likers.map(user =>{
        return(<img src={user.profilePictureSmall.url} alt='...' key={`${user.objectId}-like-${media.objectId}`} className="media-user-like-icon"/>);
      })
    }
    </div>
  );
};

export default observer((props) => {
  const { media } = props;
  const textColor = media.likedbycurrentuser ? 'txtorange' : '';
  return(
    <div className="col-xs-12 col-md-12 marginBottom5 marginTop5 d-flex justify-content-left bg-white">
      <button type="button" className="btn btn-light"><span className={`ion-heart ${textColor} text10`}> Like</span></button>
      {ShowLiker(media)}
    </div>
  );
});
