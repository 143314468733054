import React from 'react';

class SuccessMessage extends React.Component {
  render() {
    const message = this.props.message;
    if (message) {
      return(
        <div className="alert alert-success" role="alert">
          {message}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default SuccessMessage;
