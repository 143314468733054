import { makeAutoObservable, action } from 'mobx';
import agent from '../agent';

export class ExploreStore{
    isloading = false;
    medias = null;

    constructor(){
        makeAutoObservable(this);
    }

    
    loadData(){
        this.isloading = true;
        return agent.Explore.getData()
                .then(action(datas =>{
                    this.medias = datas;
                }))
                .finally(action(() =>{
                    this.isloading = false;
                }));
    }
    
    follow(userid, index){
        this.removeElement(index);
        agent.Profile.follow(userid)
        .catch(() => { console.log('failed to follow user'); });
    }
    removeElement(index){
        setTimeout(action(() => { 
            this.medias.splice(index, 1);
        }), 1000);
    }
}
export default new ExploreStore();