import { makeAutoObservable, action } from 'mobx';
import agent from '../agent';
import userStore from './userStore';
import commonStore from './commonStore';

class AuthStore {
  inProgress = false;
  errors = undefined;

  values = {
    username: '',
    email: '',
    password: '',
  };

  constructor(){
    makeAutoObservable(this);
  }

  setUsername(username) {
    this.values.username = username;
  }

  setEmail(email) {
    this.values.email = email;
  }

  setPassword(password) {
    this.values.password = password;
  }

  reset() {
    this.values.username = '';
    this.values.email = '';
    this.values.password = '';
  }

  login() {
    this.inProgress = true;
    this.errors = undefined;
    return agent.Auth.login(this.values.username, this.values.password)
      .then(user => {
        commonStore.setTokenAndId(user.sessionToken, user.user.objectId);
        userStore.setCurrentUser(user.user);
      })
      .catch(action((err) => {
        this.errors = err.response && err.response.body && err.response.body.error;
      }))
      .finally(action(() => { this.inProgress = false; }));
  }

  register() {
    this.inProgress = true;
    this.errors = undefined;
    return agent.Auth.register(this.values.username, this.values.email, this.values.password)
      .then(user => commonStore.setTokenAndId(user.sessionToken, user.objectId))
      .then(() => userStore.pullUser())
      .catch(action((err) => {
        this.errors = err.response && err.response.body && err.response.body.error;
      }))
      .finally(action(() => { this.inProgress = false; }));
  }

  logout() {
    commonStore.setToken(undefined);
    commonStore.setUserid(undefined);
    userStore.forgetUser();
    return new Promise(res => res());
  }
}

export default new AuthStore();
