import React from 'react';
import { Fade } from 'reactstrap';

export default class FollowSuggestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = { fadeIn: true };
        this.toggle = this.toggle.bind(this);
    }
    onFollowClick = () =>{
        this.props.onFollowClick(this.props.media.user.objectId, this.props.media.user.displayName, this.props.index);
        this.setState({
            fadeIn: false
        });
    }
    toggle() {
        this.setState({
            fadeIn: !this.state.fadeIn
        });
    }
    render() {
        return (
            <Fade in={this.state.fadeIn} tag="div" className="col-5 col-md-3 col-lg-2 d-flex flex-column justify-content-center align-items-center mb-1">
                <img src={this.props.media.thumbnail.url} alt={this.props.media.user.displayName} className="border border-white rounded-circle" width="100" height="100"/>
                <div>{this.props.media.user.displayName}</div>
                <button type="button" className="btn btn-sm btn-primary" onClick={this.onFollowClick}> Follow </button>
            </Fade>
        );
    }

};