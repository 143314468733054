import ReactDOM from 'react-dom';
import promiseFinally from 'promise.prototype.finally';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { configure } from 'mobx';
import App from './components/App';
import './components/css/App.css';
import AppContext from './components/AppContext';
import stores from './stores';

// For easier debugging
if (process.env.NODE_ENV !== 'production') {
  window._____APP_STATE_____ = stores;
}
promiseFinally.shim();
configure({
  enforceActions: true
});

ReactDOM.render((
  <AppContext.Provider value={stores}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AppContext.Provider>
), document.getElementById('root'));
