import moment from 'moment';

class DateHelper{
  showFriendlyDate(dateref){
    var olddt = moment(dateref);
    var diff = moment.utc().diff(olddt, 'days');
    if(diff < 6){
      return olddt.fromNow();
    }
    return olddt.format('MMMM DD');
  }
}
export default new DateHelper();
