import articlesStore from './articlesStore';
import commentsStore from './commentsStore';
import authStore from './authStore';
import commonStore from './commonStore';
import editorStore from './editorStore';
import userStore from './userStore';
import profileStore from './profileStore';
import profileMediasStore from './profileMediasStore';
import mediaFeedStore from './mediaFeedStore';
import mediaStore from './mediaStore';
import exploreStore from './exploreStore';
import notificationStore from './notificationStore';

 const stores = {
  articlesStore,
  commentsStore,
  authStore,
  commonStore,
  editorStore,
  userStore,
  profileStore,
  profileMediasStore,
  mediaFeedStore,
  mediaStore,
  exploreStore,
  notificationStore,
};

export default stores;