import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import AppContext from '../AppContext';

class CommentBox extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      commentText: ''
    };
  }
  onChange = e =>{
    this.setState({commentText: e.target.value});
  }
  handleKeyUp = e =>{
    if(e.keyCode === 13){
      if(this.props.onEnterComment){
        this.props.onEnterComment(this.props.media.objectId, this.state.commentText);
      }
      this.setState({commentText: ''});
    }
  }
  render(){
    const currentUser = this.props.userStore.currentUser;
    if(!currentUser){
      return null;
    }
    const loadingclass = this.props.isAddingCommentLoading ? 'commentloading' : '';
    return(
      <div className="col d-flex">
        <img src={currentUser.profilePictureSmall.url} alt='...' className="user-comment-icon" />
        <input type="text" className={`form-control ${loadingclass}`} value={this.state.commentText} onKeyUp={this.handleKeyUp} onChange={this.onChange} placeholder="Write Comment..." />
      </div>
    );
  }
}

export default observer((props) => {
  const { userStore } = useContext(AppContext);
  return <CommentBox {...props} userStore={userStore} />
});