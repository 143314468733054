import { makeAutoObservable, action } from 'mobx';
import agent from '../agent';

class ProfileStore {

  profile = undefined;
  profileinfo = undefined;
  isLoadingProfile = false;
  isLoadingProfileInfo = false;

  constructor(){
    makeAutoObservable(this);
  }

  clear(){
    this.profile = undefined;
    this.profileinfo = undefined;
    this.isLoadingProfile = false;
    this.isLoadingProfileInfo = false;
  }
  loadProfile(username) {
    this.isLoadingProfile = true;
    agent.Profile.post(username)
      .then(action(profile => { this.profile = profile; }))
      .finally(action(() => { this.isLoadingProfile = false; }))
  }
  loadProfileInfo(username) {
    this.isLoadingProfileInfo = true;
    agent.Profile.getinfo(username)
      .then(action(profileinfo => {
        this.profileinfo = profileinfo;
      }))
      .finally(action(() => { this.isLoadingProfileInfo = false; }))
  }
  follow() {
    if (this.profileinfo && !this.profileinfo.isfollowing) {
      this.profileinfo.isfollowing = true;      
      agent.Profile.follow(this.profile.objectId)
        .catch(action(() => { this.profileinfo.isfollowing = false; }));
    }
  }

  unfollow() {
    if (this.profileinfo && this.profileinfo.isfollowing) {
      this.profileinfo.isfollowing = false;
      agent.Profile.unfollow(this.profile.objectId)
        .catch(action(() => { this.profileinfo.isfollowing = true }));
    }
  }
}

export default new ProfileStore();
