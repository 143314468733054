import { Link } from 'react-router-dom';
import ListErrors from './ListErrors';
import React, {useContext} from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import AppContext from './AppContext';

class Login extends React.Component {

  componentWillUnmount() {
    this.props.authStore.reset();
  }

  handleUsernameChange = e => this.props.authStore.setUsername(e.target.value);
  handlePasswordChange = e => this.props.authStore.setPassword(e.target.value);
  handleSubmitForm = (e) => {
    console.log('sending login info');
    e.preventDefault();
    this.props.authStore.login()
      .then(() => {
        console.log('login success, go to home page at /');
        this.props.history.replace('/');
      });
  };

  render() {

    return (
      <div className="auth-page">
        <div className="container page">
          <div className="row">

            <div className="col-md-6 offset-md-3 col-xs-12">
              <h1 className="text-xs-center">Sign In</h1>
              <p className="text-xs-center">
                <Link to="register">
                  Need an account?
                </Link>
              </p>

              <ListErrors errors={this.props.errors} />

              <form onSubmit={this.handleSubmitForm}>
                <fieldset>

                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      placeholder="Username or E-mail"
                      value={this.props.values.username}
                      onChange={this.handleUsernameChange}
                    />
                  </fieldset>

                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="password"
                      placeholder="Password"
                      value={this.props.values.password}
                      onChange={this.handlePasswordChange}
                    />
                  </fieldset>

                  <button
                    className="btn btn-lg btn-primary pull-xs-right"
                    type="submit"
                    disabled={this.props.inProgress}
                  >
                    Sign in
                  </button>

                </fieldset>
              </form>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const loginView = observer((props) => {
  const { authStore } = useContext(AppContext);
  return (<Login {...props} authStore={authStore} 
            inProgress={authStore.inProgress} 
            errors={authStore.errors} 
            values={{...authStore.values}}
          />);
});

export default withRouter(loginView);