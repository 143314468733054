import { makeAutoObservable, observable, action } from 'mobx';
import agent from '../agent';

export class NotificationStore {
    isLoading = false;
    data = observable.array();
    totalnew = 0;
    page = 1;
    hasMore = true;

    constructor(){
        makeAutoObservable(this);
    }

    clear(){
        this.data = observable.array();
        this.isLoading = false;
        this.page = 1;
        this.hasMore = true;
    }
    resetTotalNew(){
        this.totalnew = 0;
        agent.Notification.reset();
    }
    handleFollow(item){
        this.data.forEach((el, index) =>{
            if(el.objectId === item.objectId){
                this.data.splice(index, 1, item);
                if(item.object.following){
                    agent.Profile.follow(item.actorone.objectId);
                }else{
                    agent.Profile.unfollow(item.actorone.objectId);
                }
            }
        });
    }
    loadData(){
        if(this.isLoading || !this.hasMore){
            return;
        }
        this.isLoading = true;
        return agent.Notification.getAll(this.page++)
                    .then(action(res =>{
                        if(!res || !res.results || res.results.length < 1){
                            this.hasMore = false;
                        }else{
                            this.data = this.data.concat(observable.array(res.results));
                        }
                        if(res && res.totalnew){
                            this.totalnew = res.totalnew;
                        }
                    }))
                    .finally(action(() => {
                        this.isLoading = false;
                    }));
    }
}

export default new NotificationStore();