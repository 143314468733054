import { observer } from 'mobx-react';
import React from 'react';
import Modal from 'reactstrap/lib/Modal';
import Comment from '../Comment';

export default observer(props =>{
  const { imageModalStore, toggle } = props;
  const { mediaId, showcommentmodal} = imageModalStore;
  if(!mediaId){
    return null;
  }

  const externalCloseBtn = <div className="btnclose" size='sm' onClick={toggle}>&times;</div>;
  return(
    <Modal isOpen={showcommentmodal} toggle={toggle} size="lg" className='mymodal' external={externalCloseBtn}>
      <Comment mediaId={mediaId} ismodal={true} />          
    </Modal>
  );
});