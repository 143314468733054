import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import DateHelper from '../../utils/datehelper';

const DisplaySticker = ({url}) => {
  if(url && url.length > 1){
    return(
      <p>
        <img src={url} alt="" />
      </p>
    );
  }
  return null;
};

export default observer((props) => {
  const { comment } = props;
  const { user } = comment;

  return (
    <li className="media marginBottom5">
      <img className="d-flex mr-3 user-comment-pic" src={user.profilePictureSmall && user.profilePictureSmall.url} width="50" height="50" alt={user.displayName} />
      <div className="media-body">
      <Link to={`/@${user.username}`}>
        {user.displayName}
      </Link> <span className="displayTime">{DateHelper.showFriendlyDate(comment.createdAt)}</span><br />
        {comment.comment}
        <DisplaySticker url={comment.stickerUrl} />
      </div>
    </li>
  );
});
