import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import MediaViewBody from '../CommonMedia/MediaViewBody';
import LikeViewBar from '../CommonMedia/LikeViewBar';
import LoadingSpinner from '../commons/LoadingSpinner';
import MediaCommentList from '../CommonMedia/MediaCommentList';
import CommentBox from '../CommonMedia/CommentBox';
import AppContext from '../AppContext';

class Comment extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      ismodal: props.location.state && props.location.state.modal
    }
  }
  UNSAFE_componentWillMount(){
    this.props.mediaStore.clear();
    var mediaid = this.props.mediaId;
    if(!mediaid){
      mediaid = this.props.match.params.id;
    }
    this.props.mediaStore.loadMedia(mediaid);
    this.props.mediaStore.getComment(mediaid);
  }
  handleEnterComment = (mediaId, comment) =>{
    this.props.mediaStore.addCommentAndUpdateList(this.props.mediaStore.media, comment, this.props.mediaStore.comments);
  }
  render() {
    //const mediaid = this.props.match.params.id;
    return (
      <div className="home-page">
        <div className="container page">
          <ShowMedia isLoading={this.props.isMediaLoading} 
                    media={this.props.media} 
                    comments={this.props.comments}
                    isCommentLoading={this.props.isCommentLoading}
                    handleEnterComment={this.handleEnterComment}
                    ismodal={this.props.ismodal}
          />
        </div>

      </div>
    );
  }
}

const ShowMedia = props =>{
  if(props.isLoading){
    return(
      <div className="row marginBottom10 d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }
  if(!props.media){
    return null;
  }
  var colsize = 'col-md-7';
  if(props.ismodal){
    colsize = 'col-md-10';
  }
  return(
    <React.Fragment>
    <div className="row marginBottom10 d-flex justify-content-center mt-2">
      <div className={`col-xs-12 ${colsize} noPaddingLeft noPaddingRight`}>
        <MediaViewBody media={props.media} />
      </div>
      <div className={`col-xs-12 ${colsize} noPaddingLeft noPaddingRight`}>
        <LikeViewBar media={props.media} />
      </div>
      <div className={`col-xs-12 ${colsize} noPaddingLeft noPaddingRight`}>
        <div className="card">
          <ViewCommentCount total={props.media.totalcomment} mediaid={props.media.objectId} />
          <div className="row d-flex padding10">
            <ShowCommentList comments={props.comments} isCommentLoading={props.isCommentLoading} />
          </div>
          <div className="row">
            <CommentBox media={props.media} onEnterComment={props.handleEnterComment} isAddingCommentLoading={false} />
          </div>
        </div>
      </div>
    </div>
    </React.Fragment>
  );
}
const ShowCommentList = props =>{
  if(props.isCommentLoading){
    return(
        <LoadingSpinner />
    );
  }
  if(!props.comments || props.comments.length < 1){
    return null;
  }
  return <MediaCommentList comments={props.comments} />;
}
const ViewCommentCount = props =>{
  if(props.total < 4){
    return null;
  }
  return(
    <div className="card-body">
      <span className="card-link"> Viewing all {props.total} comments</span>
    </div>
  );
}

const commentView = observer((props) => {
  const{ commonStore, mediaStore } = useContext(AppContext);
  const { isMediaLoading, media, comments, isCommentLoading } = mediaStore;
  return (<Comment {...props} commonStore={commonStore} mediaStore={mediaStore} 
    isMediaLoading={isMediaLoading}
    media={media}
    comments={comments}
    isCommentLoading={isCommentLoading}
  />);
});

export default withRouter(commentView);