import { makeAutoObservable, action, reaction } from 'mobx';
import agent from '../agent';

class CommonStore {

  appName = 'Khmer.be';
  token = window.localStorage.getItem('jwt');
  appLoaded = false;
  userid = window.localStorage.getItem('userid');
  tags = [];
  isLoadingTags = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.token,
      token => {
        if (token) {
          window.localStorage.setItem('jwt', token);
        } else {
          window.localStorage.removeItem('jwt');
        }
      }
    );
    reaction(
      () => this.userid,
      userid => {
        if (userid) {
          window.localStorage.setItem('userid', userid);
          console.log('save userid to localStorage: '+userid);
        } else {
          window.localStorage.removeItem('userid');
          console.log('removed userid to localStorage');
        }
      }
    );
  }

  loadTags() {
    this.isLoadingTags = true;
    return agent.Tags.getAll()
      .then(action(({ tags }) => { this.tags = tags.map(t => t.toLowerCase()); }))
      .finally(action(() => { this.isLoadingTags = false; }))
  }

  setToken(token) {
    this.token = token;
  }
  get userLoggedIn() {
    if(this.token) {
      return true;
    }
    return false;
  }
  setUserid(id){
    this.userid = id;
  }
  setTokenAndId(token, id){
    this.token = token;
    this.userid = id;
  }
  setAppLoaded() {
    this.appLoaded = true;
  }

}

export default new CommonStore();
