import React, {useContext} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import AppContext from './AppContext';

export default observer((props) => {
  const stores = useContext(AppContext);
  const { currentUser } = stores.userStore;
  const { ...restProps } = props;
  if (currentUser) return <Route {...restProps} />;
  return <Redirect to="/" />;
});