import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import DateHelper from '../../utils/datehelper';

const showCaption = caption =>{
  if(!caption || caption.length < 1){
    return null;
  }
  return(
    <div className="card-body">
      <p className="card-text">{caption}</p>
    </div>
  );
};

export default observer((props) => {
  const { media } = props;
  const { user } = media;
  const onImageClick = () => {
    if(props.onImageClick){
      props.onImageClick(props.media.src.url, props.media.srcwidth, props.media.srcheight, props.media.objectId);
    }
  }
  return(
    <div className="card">
      <div className="card-header">
        <div className="media">
          <Link to={`/@${user.username}`}>
            <img className="d-flex mr-3 media-header-user-icon" src={user.profilePictureSmall && user.profilePictureSmall.url} width="50" height="50" alt={user.displayName} />
          </Link>
          <div className="media-body">
            <div className="d-flex justify-content-between">
              <b><Link to={`/@${user.username}`}>{user.displayName}</Link></b>
              <div>
              <span className="ion-heart txtorange"></span> {media.totallike} <span className="ion-chatbubble txtdarkblue"></span> {media.totalcomment}
              </div>
            </div>
            <span className="text-muted">{DateHelper.showFriendlyDate(media.createdAt)}</span>
          </div>
        </div>
      </div>
      {showCaption(media.caption)}
      <img className="card-img-bottom" src={media.src.url} alt="..." onClick={onImageClick}/>
    </div>
  );

});
