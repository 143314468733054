import React from 'react';

const ViewAllCommentLink = props =>{
    if(props.total < 4){
      return null;
    }
    return(
      <div className="card-body">
        <button type="button" className="btn btn-link" onClick={props.onClick}> View all {props.total} comments</button>
      </div>
    );
  };
export default ViewAllCommentLink;