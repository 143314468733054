import { makeAutoObservable, observable, action } from 'mobx';
import agent from '../agent';


export class ProfileMediasStore {
  isLoading = false;
  page = 1;
  totalPagesCount = 0;
  mediasRegistry = observable.array();
  isAddingCommentLoading = false;
  hasmore = true;

  constructor(){
    makeAutoObservable(this);
  }

  get medias() {
    return this.mediasRegistry;
  };
  clear() {
    this.mediasRegistry.clear();
    this.page = 1;
    this.isAddingCommentLoading = false;
  }
  setPage(page) {
    this.page = page;
  }
  getMedia(mediaid) {
    return this.mediasRegistry.find(data => data.objectId === mediaid);
  }
  loadUserMedia(username){
    this.isLoading = true;

    return agent.Medias.byUser(username, this.page++)
            .then(action(datas =>{
              if(datas.length < 1){
                this.hasmore = false;
              }else{
                this.mediasRegistry = this.mediasRegistry.concat(datas);
              }
            }))
            .finally(action(() => { this.isLoading = false; }));
  }

}

export default new ProfileMediasStore();
