import { makeAutoObservable, observable, action } from 'mobx';
import agent from '../agent';


export class MediaStore {
  isMediaLoading = false;
  isCommentLoading = false;
  isAddingCommentLoading = false;
  media = undefined;
  comments = observable.array();;
  commentPage = 1;
  hasMoreComment = true;

  constructor() {
    makeAutoObservable(this);
  }

  get medias() {
    return this.media;
  };
  clear() {
    this.media = undefined;
    this.isMediaLoading = false;
    this.isCommentLoading = false;
    this.isAddingCommentLoading = false;
    this.comments = observable.array();
    this.commentPage = 1;
    this.hasMoreComment = true;
  }
  
  loadMedia(mediaid){
    this.isMediaLoading = true;
    return agent.Medias.getOne(mediaid)
            .then(action(mediaObj =>{
              this.media = mediaObj;
            }))
            .finally(action(() => { this.isMediaLoading = false; }));
  }
  getComment(mediaid){
    this.isCommentLoading = true;
    return agent.Medias.getComment(mediaid, this.commentPage++, 20)
            .then(action(commentList => {
              if(commentList.length < 1){
                this.hasMoreComment = false;
              }else{
                this.comments = this.comments.concat(commentList);
              }
            }))
            .finally(action(() =>{this.isCommentLoading = false;}));
  }
  addComment(mediaObject, comment){
    this.isAddingCommentLoading = true;
    return this.saveComment(mediaObject, comment, action(data =>{
      mediaObject.latestcomments.push(data.comment);
    }));
  }
  addCommentAndUpdateList(mediaObject, comment, commentList){
    this.isAddingCommentLoading = true;
    return this.saveComment(mediaObject, comment, action(data =>{
      commentList.push(data.comment);
    }));
  }
  saveComment(mediaObject, comment, callback){
    return agent.Medias.addComment(mediaObject.objectId, comment)
                .then(callback)
                .finally(action(() =>{
                  this.isAddingCommentLoading = false;
                }));
  }
}

export default new MediaStore();
