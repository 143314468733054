import React, { useContext } from 'react';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';
import { observer } from 'mobx-react';
import NotificationBox from './NotificationBox';
import MessageHead from './MessageHead';
import AppContext from '../AppContext';
import { Link } from 'react-router-dom';

class HeaderNav extends React.Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  render() {
    return (
    <Navbar color="kbnavbg" dark expand="md" fixed="top">
        <div className="container">
        <Link to="/" className="mr-auto navbar-brand">{this.props.commonStore.appName}</Link>
        {this.props.currentUser && <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />}
        <UserNav {...this.props} isOpen={!this.state.collapsed} />
        </div>
    </Navbar>
    );
  }
}

const UserNav = props => {
  if(!props.userStore || !props.currentUser){
    return null;
  }
  return(
    <Collapse isOpen={props.isOpen} navbar>
      <Nav className="ml-auto" navbar>
          <NavItem>
            <MessageHead />
          </NavItem>
          <NavItem className="ml-1">
            <NotificationBox />
          </NavItem>
          <NavItem>
            <Link to="/settings" className="nav-link"><span className="ion-gear-a" />Settings</Link>
          </NavItem>
          <NavItem>
            <Link to={`/@${props.userStore.currentUser.username}`} className="nav-link">
            {props.currentUser.profilePictureSmall && <img src={props.currentUser.profilePictureSmall.url} className="user-pic" alt="" />}
            {props.currentUser.username}
            </Link>
          </NavItem>
      </Nav>
    </Collapse>
  );
}

export default observer((props) => {
  const { userStore, commonStore } = useContext(AppContext);
  return <HeaderNav {...props} userStore={userStore} commonStore={commonStore} />;
});
