import React, {useContext} from 'react';
import LoadingSpinner from '../commons/LoadingSpinner';
import { observer } from 'mobx-react';
import MediaView from '../CommonMedia/MediaView';
import {Waypoint} from 'react-waypoint';
import { ImageModalStore } from '../../stores/imageModalStore';
import MediaModal from '../CommonMedia/MediaModal';
import MediaCommentModal from '../CommonMedia/MediaCommentModal';
import AppContext from '../AppContext';

const imageModalStore = new ImageModalStore();

class ProfileMediaList extends React.Component {
  constructor(props){
    super(props);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
  }
  componentWillUnmount(){
    imageModalStore.closeCommentModal();
    imageModalStore.closeModal();
  }
  handleLoadMore(){
    if(this.props.profileMediasStore.hasmore){
        this.props.profileMediasStore.loadUserMedia(this.props.username);
    }
  }
  handlePositionChange=({ previousPosition, currentPosition, event }) => {
    if(previousPosition && previousPosition === 'below'){
      this.handleLoadMore();
    }
  }
  handleImageClick = (url,width, height, id) =>{
    imageModalStore.openModal(url, width, height, id);
  }
  onViewCommentClick = (mediaId) =>{
    imageModalStore.openCommentModal(mediaId);
    var addr = '/viewcomment/'+mediaId;
    window.history.pushState({page: addr}, 'View Photo and All Comments', addr);
  }
  onPopState = (event) =>{
    imageModalStore.closeCommentModal();
  }
  onCloseModal = (e) =>{
    imageModalStore.toggle(e);
  }
  onCloseCommentModal = (e) =>{
    imageModalStore.toggleCommentModal(e);
    window.history.back();
  }
  render() {
    const { isLoading } = this.props.profileMediasStore;
    const viewlist = this.props.medias.map((media,index) => {
      
      return(
        <React.Fragment key={'promediaviewkey-'+media.objectId}>
        <MediaView media={media} key={'promediaviewid-'+media.objectId} onImageClick={this.handleImageClick} onViewCommentClick={this.onViewCommentClick}/>
        
        </React.Fragment>
      );
    });
    return (
      <div className="container">
      {viewlist}
      {isLoading && <LoadingSpinner />}
      <Waypoint onPositionChange={this.handlePositionChange} />
      <MediaCommentModal imageModalStore={imageModalStore} toggle={this.onCloseCommentModal} />
      <MediaModal imageModalStore={imageModalStore} toggle={this.onCloseModal} />
      </div>
    );
  }
}

export default observer((props) => {
  const { profileMediasStore } = useContext(AppContext);
  return <ProfileMediaList {...props} profileMediasStore={profileMediasStore} />;
});