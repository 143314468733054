import ListErrors from './ListErrors';
import SuccessMessage from './SuccessMessage';
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import AppContext from './AppContext';

class SettingsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: '',
      username: '',
      about: '',
      email: '',
      password: ''
    };

    this.updateState = field => ev => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };

    this.submitForm = ev => {
      ev.preventDefault();

      const user = Object.assign({}, this.state);
      if (!user.password) {
        delete user.password;
      }
      console.log('Updating: ');
      console.log(user);
      this.props.onSubmitForm(user);
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.currentUser) {
      Object.assign(this.state, {
        displayName: this.props.currentUser.displayName,
        username: this.props.currentUser.username,
        about: this.props.profileinfo.about,
        email: this.props.currentUser.email
      });
    }else{
      console.log('No currentUser');
    }
  }

  render() {
    return (
      <form onSubmit={this.submitForm}>
        <fieldset>

          <fieldset className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Display Name"
              value={this.state.displayName}
              onChange={this.updateState('displayName')}
            />
          </fieldset>

          <fieldset className="form-group">
            <input
              className="form-control form-control-lg"
              type="text"
              placeholder="Username"
              value={this.state.username}
              onChange={this.updateState('username')}
              disabled="disabled"
            />
          </fieldset>

          <fieldset className="form-group">
            <textarea
              className="form-control form-control-lg"
              rows="8"
              placeholder="Short bio about you"
              value={this.state.about}
              onChange={this.updateState('about')}
            >
            </textarea>
          </fieldset>

          <fieldset className="form-group">
            <input
              className="form-control form-control-lg"
              type="email"
              placeholder="Email"
              value={this.state.email}
              disabled="disabled"
            />
          </fieldset>

          <fieldset className="form-group">
            <input
              className="form-control form-control-lg"
              type="password"
              placeholder="Enter Password to update"
              value={this.state.password}
              onChange={this.updateState('password')}
            />
          </fieldset>

          <button
            className="btn btn-lg btn-primary pull-xs-right"
            type="submit"
            disabled={this.props.userStore.updatingUser}
          >
            Update Settings
          </button>

        </fieldset>
      </form>
    );
  }
}


class Settings extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.userStore.clearUpdatingUserStat();
    this.loadData();
  }
  loadData(){
    this.props.profileStore.loadProfile(this.props.currentUser.username);
    this.props.profileStore.loadProfileInfo(this.props.currentUser.username);
    console.log('Setting page loading data');
  }

  handleClickLogout = () =>
    this.props.authStore.logout()
      .then(() => this.props.history.replace('/'));

  render() {

    return (
      <div className="settings-page">
        <div className="container page">
          <div className="row">
            <div className="col-md-6 offset-md-3 col-xs-12">

              <h1 className="text-xs-center">Your Settings</h1>

              <ListErrors errors={this.props.updatingUserErrors} />
              <SuccessMessage message={this.props.updatingUserSuccess} />
              
              {!this.props.isLoadingProfile && !this.props.isLoadingProfileInfo && this.props.profileinfo &&
              <SettingsForm
                userStore={this.props.userStore}
                currentUser={this.props.currentUser}
                profileStore={this.props.profileStore}
                profileinfo={this.props.profileinfo}
                onSubmitForm={userdata => this.props.userStore.updateUser(userdata)} />
              }
              <hr />

              <button
                className="btn btn-outline-danger"
                onClick={this.handleClickLogout}
              >
                Or click here to logout.
              </button>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const view = observer((props) => {
  const { userStore, authStore, profileStore } = useContext(AppContext);
  const { profileinfo, isLoadingProfile, isLoadingProfileInfo } = profileStore;
  const { currentUser, updatingUserSuccess, updatingUserErrors} = userStore;
  return (<Settings {...props} userStore={userStore} authStore={authStore} 
    profileStore={profileStore} 
    currentUser={currentUser} 
    profileinfo={profileinfo}
    isLoadingProfile={isLoadingProfile}
    isLoadingProfileInfo={isLoadingProfileInfo}
    updatingUserErrors={updatingUserErrors}
    updatingUserSuccess={updatingUserSuccess}
  />);
});
export default withRouter(view);
