import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ListErrors from '../ListErrors';
import AppContext from '../AppContext';

class Public extends React.Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          tooltipOpen: false,
          errorMessage: null
        };
    }
    componentWillUnmount() {
        this.props.authStore.reset();
    }
    
    onFbLogin = () =>{
        console.log('FB is not loaded, showing sign up dialog box');
    }
    handleUsernameChange = e => this.props.authStore.setUsername(e.target.value);
    handlePasswordChange = e => this.props.authStore.setPassword(e.target.value);
    handleSubmitForm = (e) => {
        e.preventDefault();
        this.props.authStore.login()
        .then(() => this.props.history.replace('/'));
    };
    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
  render(){
    const { values, errors, inProgress } = this.props;
    return (
    <div className="container mt-xs-1 mt-md-2 mt-lg-3">
        <div className="row d-flex justify-content-center">
            <div className="col-10 col-sm-10 sx-auto col-md-6 col-lg-5 order-1 order-sm-1 order-md-0 order-lg-0 ">
                <h1 className="text20">Meet Khmer around the world</h1>
                <div className="row">
                    <div className="col d-flex flex-row">
                        <div><span className="text40 ion-ios-search-strong lightBlue"></span></div>
                        <div className="align-self-center ml-2"><span className="text12">Discover Khmer near you</span></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col d-flex flex-row">
                        <div><span className="text40 ion-ios-people lightBlue"></span></div>
                        <div className="align-self-center ml-2"><span className="text12">Make new friends</span></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col d-flex flex-row">
                        <div><span className="text40 ion-images lightBlue"></span></div>
                        <div className="align-self-center ml-2"><span className="text12">See photos and videos in News Feed</span></div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 order-0 order-sm-0 order-md-1 order-lg-1">
                <div className="border m-3 p-3 justify-content-center">
                    <h1 className="text-center font-weight-bold mb-2 txtdarkblue">Khmer.be</h1>
                    <ListErrors errors={errors} />
                    <form onSubmit={this.handleSubmitForm}>
                        <div className="form-group">
                            <input className="form-control" placeholder="E-mail" 
                                value={values.username}
                                onChange={this.handleUsernameChange} />
                        </div>
                        <div className="form-group">
                            <input type="password" className="form-control" placeholder="Password" 
                                value={values.password}
                                onChange={this.handlePasswordChange} />
                        </div>
                        <button type="submit" className="btn btn-login btn-block" disabled={inProgress}> Submit </button>
                    </form>
                </div>
                <div className="border m-3 p-3 text-center">
                    <div className="mb-1">Don't have an account?</div>
                    <div className="mb-1">Mobile App coming soon. Download mobile app to create an account</div>
                </div>
            </div>
        </div>
    </div>
    );
    };
}

const view = observer((props) => {
    const { authStore } = useContext(AppContext);
    const { values, errors, inProgress } = authStore;
    return <Public {...props} authStore={authStore} errors={errors} inProgress={inProgress} values={{...values}} />;
});

export default withRouter(view);