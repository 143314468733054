import { makeAutoObservable } from 'mobx';

export class ImageModalStore {
  showmodal = false;
  showcommentmodal = false;
  imageurl = undefined;
  imagewidth = undefined;
  imageheight = undefined;
  mediaId = undefined;

  constructor(){
    makeAutoObservable(this);
  }
  
  openModal = (src,width, height, id) => {
    this.showmodal = true;
    this.imageurl = src;
    this.imagewidth = width;
    this.imageheight = height;
    this.mediaId = id;
  };
  
  closeModal = (e) => {
    this.showmodal = false;
  };

  toggle = (e) => {
    this.showmodal = !this.showmodal;
  };

  openCommentModal = (id) => {
    this.showcommentmodal = true;
    this.mediaId = id;
  };

  closeCommentModal = (e) => {
    this.showcommentmodal = false;
  };

  toggleCommentModal = (e) => {
    this.showcommentmodal = !this.showcommentmodal;
  };
}
export default new ImageModalStore();