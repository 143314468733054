import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import MediaCommentList from './MediaCommentList';
import MediaViewBody from './MediaViewBody';
import LikeViewBar from './LikeViewBar';
import CommentBox from './CommentBox';
import ViewAllCommentLink from './ViewAllCommentLink';
import AppContext from '../AppContext';

class MediaView extends React.Component {
  handleEnterComment = (mediaId, comment) =>{
    this.props.mediaStore.addComment(this.props.media, comment);
  }
  handleImageClick = (url, width, height, id) =>{
    if(this.props.onImageClick){
      this.props.onImageClick(url, width, height, id);
    }
  }
  onViewCommentClick = () =>{
    this.props.onViewCommentClick(this.props.media.objectId);
  }
  render() {
    const { media } = this.props;
    return (
      <div className="row marginBottom10 d-flex justify-content-center">
        <div className="col-12 noPaddingLeft noPaddingRight">
          <MediaViewBody media={media} onImageClick={this.handleImageClick}/>
        </div>
        <div className="col-12 noPaddingLeft noPaddingRight">
        <LikeViewBar media={media} />
        </div>
        <div className="col-12 noPaddingLeft noPaddingRight">
          <div className="card">
            <ViewAllCommentLink total={media.totalcomment} mediaid={media.objectId} onClick={this.onViewCommentClick} />
            <div className="row d-flex padding10">
              <MediaCommentList comments={media.latestcomments} />
            </div>
            <div className="row">
              <CommentBox media={media} onEnterComment={this.handleEnterComment} isAddingCommentLoading={this.props.mediaStore.isAddingCommentLoading} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer((props) => {
  const { mediaStore } = useContext(AppContext);
  return <MediaView {...props} mediaStore={mediaStore} />;
});
