import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Public from './public';
import MediaFeedList from './MediaFeedList';
import ExploreBox from '../CommonMedia/ExploreBox';
import AppContext from '../AppContext';

class Home extends React.Component {
  render() {
    const {token, appName, isLoading, medias} = this.props;
    if(!token){
      return <Public appName={appName} />
    }
    
    return (
      <div role="main" className="container mt-2">
        <ExploreBox />
        <div className="row d-flex justify-content-center">
          <div className="col-md-7 col-xs-12">
            <MediaFeedList isLoading={isLoading} medias={medias} />
          </div>
        </div>
      </div>
    );
  }
}

const homeView = observer((props) => {
  const { commonStore, mediaFeedStore } = useContext(AppContext);
  const {token, appName } = commonStore;
  return (<Home {...props} appName={appName}
    token={token} 
    isLoading={mediaFeedStore.isLoading}
    medias={mediaFeedStore.medias}
  />);
});

export default withRouter(homeView);