import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import {Waypoint} from 'react-waypoint';
import LoadingSpinner from '../commons/LoadingSpinner';
import MediaView from '../CommonMedia/MediaView';
import { ImageModalStore } from '../../stores/imageModalStore';
import MediaModal from '../CommonMedia/MediaModal';
import MediaCommentModal from '../CommonMedia/MediaCommentModal';
import AppContext from '../AppContext';

const imageModalStore = new ImageModalStore();

class MediaFeedList extends React.Component {
  constructor(props){
    super(props);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
  }
  componentDidMount(){
    this.props.mediaFeedStore.loadMediaFeed();
    window.onpopstate = this.onPopState;
  }
  componentWillUnmount(){
    imageModalStore.closeCommentModal();
    imageModalStore.closeModal();
  }
  handleLoadMore(){
    if(this.props.mediaFeedStore.hasmore){
        this.props.mediaFeedStore.loadMediaFeed();
    }
  }
  handlePositionChange=({ previousPosition, currentPosition, event }) => {
    if(previousPosition && previousPosition === 'below'){
      this.handleLoadMore();
    }
  }
  handleImageClick = (url,width, height, id) =>{
    imageModalStore.openModal(url, width, height, id);
  }
  onViewCommentClick = (mediaId) =>{
    imageModalStore.openCommentModal(mediaId);
    var addr = '/viewcomment/'+mediaId;
    window.history.pushState({page: addr}, 'View Photo and All Comments', addr);
  }
  onPopState = (event) =>{
    imageModalStore.closeCommentModal();
  }
  onCloseModal = (e) =>{
    imageModalStore.toggle(e);
  }
  onCloseCommentModal = (e) =>{
    imageModalStore.toggleCommentModal(e);
    window.history.back();
  }
  render() {
    const { isLoading } = this.props;
    const viewlist = this.props.medias.map((media,index) => {
      return(
        <React.Fragment key={'mediaviewkey-'+media.objectId}>
        <MediaView media={media} key={'mediaviewid-'+media.objectId} onImageClick={this.handleImageClick} onViewCommentClick={this.onViewCommentClick}/>
        
        </React.Fragment>
      );
    });
    return (
      <div className="container">
        {viewlist}
        {isLoading && <LoadingSpinner />}
        <Waypoint onPositionChange={this.handlePositionChange} />
        <MediaCommentModal imageModalStore={imageModalStore} toggle={this.onCloseCommentModal} />
        <MediaModal imageModalStore={imageModalStore} toggle={this.onCloseModal} />
      </div>
    );
  }
}

const view = observer((props) => {
  const{ mediaFeedStore } = useContext(AppContext);
  return (<MediaFeedList {...props} mediaFeedStore={mediaFeedStore} />);
});

export default withRouter(view);