import React from 'react';
import { observer } from 'mobx-react';
import { Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';


class MessageHead extends React.Component{
    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }
    toggle() {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
    }
    render(){
        var items = <li className="list-group-item">You have 0 message</li>;
        return(
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle
          onClick={this.toggle}
          data-toggle="dropdown"
          aria-expanded={this.state.dropdownOpen}
        >
          <span className="ion-ios-chatbubble noti-icon" />
        </DropdownToggle>
        <DropdownMenu
            modifiers={{
            setMaxHeight: {
                enabled: true,
                order: 890,
                fn: (data) => {
                return {
                    ...data,
                    styles: {
                    ...data.styles,
                    overflow: 'auto',
                    maxHeight: 400,
                    minWidth: 300,
                    },
                };
                },
            },
            }}
        >
        <ul className="list-group list-group-flush">
            {items}
        </ul>
        </DropdownMenu>
      </Dropdown>
        );
    }
}

export default observer(props => <MessageHead {...props} />);