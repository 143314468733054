import React from 'react';
import { observer } from 'mobx-react';
import MediaCommentView from './MediaCommentView';

export default observer((props) =>{
  return (
      <div className="container">
        <ul className="list-unstyled">
        {
          props.comments.map(comment => {
            return(
              <MediaCommentView comment={comment} key={'commentid-'+comment.objectId} />
            );
          })
        }
        </ul>
      </div>
    );
  }
);
